@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

@include mat.core();

@import 'sewio-variables.scss';
@import 'fonts.scss';

a {
    text-decoration: underline;
}

html,
body {
    width: 100%;
    height: 100%;
    font-family: 'Sofia Pro', sans-serif;
    font-weight: 300;
}

body {
    margin: 0;
}

@media print {
    html,
    body {
        height: initial;
    }
}

/* THEME */

$sewio-palette: (
    50: #e6f5eb,
    100: #cdebd7,
    200: #9bd7b0,
    300: #69c388,
    400: #37af61,
    500: #059b39,
    600: #047c2e,
    700: #035d22,
    800: #023e17,
    900: #022f11,
    contrast: (
        50: #131418,
        100: #131418,
        200: #131418,
        300: #131418,
        400: #131418,
        500: #fafafa,
        600: #fafafa,
        700: #fafafa,
        800: #fafafa,
        900: #fafafa,
    ),
);

$sewio-accent-palette: (
    50: #e6f5eb,
    100: #cdebd7,
    200: #9bd7b0,
    300: #69c388,
    400: #37af61,
    500: #059b39,
    600: #047c2e,
    700: #035d22,
    800: #023e17,
    900: #022f11,
    contrast: (
        50: #131418,
        100: #131418,
        200: #131418,
        300: #131418,
        400: #131418,
        500: #fafafa,
        600: #fafafa,
        700: #fafafa,
        800: #fafafa,
        900: #fafafa,
    ),
);

$sewio-warn-palette: (
    50: #feefe5,
    100: #fde4d3,
    200: #fbc3a7,
    300: #f4987a,
    400: #e96f57,
    500: #db3425,
    600: #bc1b1b,
    700: #9d121d,
    800: #7f0b1e,
    900: #69071f,
    contrast: (
        50: #131418,
        100: #131418,
        200: #131418,
        300: #131418,
        400: #131418,
        500: #fafafa,
        600: #fafafa,
        700: #fafafa,
        800: #fafafa,
        900: #fafafa,
    ),
);

$sewio-primary: mat.define-palette($sewio-palette, 500);
$sewio-accent: mat.define-palette($sewio-accent-palette, 500);
$sewio-warn: mat.define-palette($sewio-warn-palette, 500);

$sewio-typography: mat.define-typography-config(
    $headline-1:
        mat.define-typography-level(
            $font-family: Sofia Pro,
            $font-size: 96px,
        ),
    $headline-2:
        mat.define-typography-level(
            $font-family: Sofia Pro,
            $font-size: 60px,
        ),
    $headline-3:
        mat.define-typography-level(
            $font-family: Sofia Pro,
            $font-size: 48px,
        ),
    $headline-4:
        mat.define-typography-level(
            $font-family: Sofia Pro,
            $font-size: 34px,
        ),
    $headline-5:
        mat.define-typography-level(
            $font-family: Sofia Pro,
            $font-size: 24px,
        ),
    $headline-6:
        mat.define-typography-level(
            $font-family: Sofia Pro,
            $font-size: 20px,
        ),
    $subtitle-1:
        mat.define-typography-level(
            $font-family: Sofia Pro,
            $font-size: 16px,
        ),
    $subtitle-2:
        mat.define-typography-level(
            $font-family: Sofia Pro,
            $font-size: 14px,
        ),
    $body-1:
        mat.define-typography-level(
            $font-family: Sofia Pro,
            $font-size: 16px,
        ),
    $body-2:
        mat.define-typography-level(
            $font-family: Sofia Pro,
            $font-size: 14px,
        ),
    $button:
        mat.define-typography-level(
            $font-family: Sofia Pro,
            $font-size: 14px,
            $font-weight: 400,
        ),
    $caption:
        mat.define-typography-level(
            $font-family: Sofia Pro,
            $font-size: 12px,
        ),
);

$sewio-theme: mat.define-light-theme(
    (
        color: (
            primary: $sewio-primary,
            accent: $sewio-accent,
            warn: $sewio-warn,
        ),
        typography: $sewio-typography,
    )
);

@include mat.all-component-themes($sewio-theme);

/* SCROLLBAR */

::-webkit-scrollbar {
    width: 4px;
    height: 4px;

    @media only screen and (min-width: $mobile-breakpoint) {
        width: 8px;
        height: 8px;
    }
}

::-webkit-scrollbar-track {
    background: #ffffff00;
}

::-webkit-scrollbar-thumb {
    background: #ccd1d7;
    border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
    background: #bdc4cc;
}

.no-wrap {
    white-space: nowrap;
}

.vertical-center {
    display: flex;
    align-items: center;
}

.unselectable {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
}

.mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: theme('colors.light.DEFAULT');
    --mat-mdc-snack-bar-button-color: theme('colors.brand.DEFAULT');
    --mdc-snackbar-supporting-text-color: theme('colors.dark.DEFAULT');
}
