/* Material Icons */

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/materialicons/standard.woff2');
}

@font-face {
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/materialicons/outlined.woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* Material Symbols */

@font-face {
    font-family: 'Material Symbols Rounded';
    font-style: normal;
    font-weight: 400;
    src: url(./assets/fonts/materialsymbols/material-symbols.woff2)
        format('woff2');
}

.material-symbols-rounded {
    font-family: 'Material Symbols Rounded';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* Sofia Pro */

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Ultra Light.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Extra Light.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Light.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Regular.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Medium.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Semi Bold.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Bold.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Black.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Ultra Light Italic.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Extra Light Italic.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Light Italic.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Regular Italic.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Medium Italic.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Semi Bold Italic.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Bold Italic.otf');
}

@font-face {
    font-family: 'Sofia Pro';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('/assets/fonts/sofia-pro/Sofia Pro Black Italic.otf');
}

/* Montserrat */

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('/assets/fonts/montserrat/latin-100.woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/montserrat/cyrillic-ext-400.woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/montserrat/cyrillic-400.woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/montserrat/vietnamese-400.woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
        U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/montserrat/latin-ext-400.woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/montserrat/latin-400.woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/montserrat/cyrillic-ext-500.woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/montserrat/cyrillic-500.woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/montserrat/vietnamese-500.woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
        U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/montserrat/latin-ext-500.woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/montserrat/latin-500.woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/montserrat/cyrillic-ext-700.woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
        U+FE2E-FE2F;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/montserrat/cyrillic-700.woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/montserrat/vietnamese-700.woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
        U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/montserrat/latin-ext-700.woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/montserrat/latin-700.woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: logoFont;
    src: url('/assets/fonts/logo.ttf');
}
